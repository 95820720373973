import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('PeriodExercise', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const guid = $injector.get('guid');
        const IsPeriodItem = $injector.get('IsPeriodItem');
        const moment = $injector.get('moment');
        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.alias('PeriodExercise');
            this.embeddedIn('period');
            this.include(IsPeriodItem);

            this.setCallback('after', 'copyAttrsOnInitialize', function afterCopyAttrsOnInitialize() {
                this.id = this.id || guid.generate();
                this.hours_offset_from_end = this.hours_offset_from_end || 0;
            });

            // Overriding the hour offset calculation specifically for exercises
            // will allow minute precision rather than rounded hour precision
            // eslint-disable-next-line func-names
            this.prototype._getRelativeHourOffsetFromPeriodEnd = function (date) {
                const endDate = moment(this.period().endDate);
                const inputDate = moment(date);

                return -endDate.diff(inputDate, 'hours', true);
            };

            Object.defineProperty(this.prototype, 'date', {
                get() {
                    return this._getCachedRelativeDateFromPeriodEnd('date', 'hours_offset_from_end', 'hours');
                },
                set(date) {
                    this.hours_offset_from_end = this._getRelativeHourOffsetFromPeriodEnd(date);
                },
            });

            Object.defineProperty(this.prototype, 'cohort', {
                get() {
                    return this.period().cohort();
                },
            });

            return {};
        });
    },
]);
