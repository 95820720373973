import transformKeyCase from 'Utils/transformKeyCase';
import {
    type LessonProgress,
    type ProjectProgress,
    type StreamProgress,
    type GetAllProgressResponse,
    type useContentProgressResponse,
} from '../ContentProgress.types';

export function convertGetAllProgressResponse(
    iguanaFormattedResponse: GetAllProgressResponse,
): useContentProgressResponse {
    return {
        streamProgress: iguanaFormattedResponse.streamProgress.map(r =>
            transformKeyCase<StreamProgress>(r.asJson(), { to: 'camelCase' }),
        ),
        lessonProgress: iguanaFormattedResponse.lessonProgress.map(r =>
            transformKeyCase<LessonProgress>(r.asJson(), { to: 'camelCase' }),
        ),
        projectProgress: iguanaFormattedResponse.projectProgress.map(r =>
            transformKeyCase<ProjectProgress>(r, { to: 'camelCase' }),
        ),
    };
}
