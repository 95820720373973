import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ContainerProps = {
    children?: ReactNode;
    className?: string;
};

// See Notes section of story for details on how to use this component.
export function Container({ children, className }: ContainerProps) {
    return <div className={twMerge('mx-auto w-[1170px] max-w-full px-2.5 sm:px-7.5', className)}>{children}</div>;
}
