import { type PlaylistSnakeCasedAttrs } from 'Playlists';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { type LearnerContentCache as LearnerContentCacheType } from 'LearnerContentCache';
import { getCohort } from 'Users';
import { type StreamProgressIguanaObject } from '../ContentProgress.types';

export function isPlaylistComplete(
    playlist: PlaylistSnakeCasedAttrs,
    streamProgresses: StreamProgressIguanaObject[],
): boolean {
    const playlistStreamLocalePackIds = playlist.stream_entries.map(entry => entry.locale_pack_id);
    return playlistStreamLocalePackIds.every(
        localePackId =>
            !!streamProgresses.find(progress => !!progress.completed_at && progress.locale_pack_id === localePackId),
    );
}

export function playlistHasAtLeastOneCourseComplete(
    playlist: PlaylistSnakeCasedAttrs,
    streamProgresses: StreamProgressIguanaObject[],
): boolean {
    const playlistStreamLocalePackIds = playlist.stream_entries.map(entry => entry.locale_pack_id);
    return playlistStreamLocalePackIds.some(
        localePackId =>
            !!streamProgresses.find(progress => !!progress.completed_at && progress.locale_pack_id === localePackId),
    );
}

export async function hasCompletedFoundations($injector: ng.auto.IInjectorService): Promise<boolean> {
    const LearnerContentCache = $injector.get<LearnerContentCacheType>('LearnerContentCache');
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    if (!currentUser) return false;

    const cohort = getCohort(currentUser);
    if (!cohort) return false;

    const { available_playlists } = (await LearnerContentCache.ensureStudentDashboard()).result[0];
    const progress = await currentUser.progress.getAllProgress();
    const foundationsPlaylist = available_playlists.find(
        p => p.localePackId === cohort.foundationsPlaylistLocalePack.id,
    );

    if (!foundationsPlaylist) return false;

    return isPlaylistComplete(foundationsPlaylist, progress.streamProgress);
}
